import React from 'react';
import { Container, Row } from 'reactstrap';
import SectionTitle from '../common/section-title';
import ServiceBox from './service-box';

const SERVICES = [
  {
    id: 1,
    title: 'Creative Design',
    icon: 'pe-7s-diamond',
    description:
      'We work with your team to create beautiful designs, wireframes and prototypes that bring your ideas to life.',
  },
  {
    id: 2,
    title: 'Mobile',
    icon: 'pe-7s-phone',
    description:
      'We specialize in native application development for iOS (iPhone and iPad) and Android.',
  },
  {
    id: 3,
    title: 'Web',
    icon: 'pe-7s-monitor',
    description:
      'We build beautiful and responsive web applications your users will love interacting with.',
  },
  {
    id: 4,
    title: 'Software Development',
    icon: 'pe-7s-science',
    description:
      "We adopt modern tools and practices, building on the industry's collective learning to deliver high-quality software.",
  },
  {
    id: 5,
    title: 'Consulting',
    icon: 'pe-7s-users',
    description:
      'We have gained a lot of experience developing web and mobile apps. We can accompany your team in its own journey.',
  },
  {
    id: 6,
    title: 'Discovery',
    icon: 'pe-7s-map-2',
    description:
      'We work with you to identify key opportunities to create apps for your employees and customers.',
  },
  {
    id: 7,
    title: 'Enhancements',
    icon: 'pe-7s-tools',
    description:
      'We help you update and maintain your apps, and work with your developers to keep your apps performant.',
  },
  {
    id: 8,
    title: 'Social Media',
    icon: 'pe-7s-share',
    description:
      'We can help you leverage social media channels to facilitate adoption of your existing apps',
  },
  {
    id: 9,
    title: 'Analytics',
    icon: 'pe-7s-graph2',
    description:
      'We integrate performance and analytics into your existing apps, allowing you to quickly see trends and issues.',
  },
];

const Services = () => (
  <React.Fragment>
    <section className="section bg-light" id="services">
      <Container>
        <SectionTitle
          title="Our Services"
          description="We craft beautiful and engaging applications, delivering high quality for our clients at reasonable cost. Our team of experienced engineers strives for excellence through all phases of our engagement."
        />
        <Row className="margin-t-30">
          {SERVICES.map((service) => (
            <ServiceBox
              key={service.id}
              title={service.title}
              icon={service.icon}
              description={service.description}
            />
          ))}
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default Services;
