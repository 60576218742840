import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SectionTitle from '../common/section-title';
import ContactForm from './contact-form';

const ContactUs = () => {
  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          <SectionTitle
            title="Get In Touch"
            description="We operate out of Ottawa, Ontario, Canada, and serve clients all over the world."
          />
          <Row>
            <Col lg="4">
              <div className="mt-4 pt-4">
                <p className="mt-4">
                  <span className="h5">Phone:</span>
                  <br />{' '}
                  <span className="text-muted d-block mt-2">
                    +1 613 324 0028
                  </span>
                </p>
                <p className="mt-4">
                  <span className="h5">Email:</span>
                  <br />{' '}
                  <span className="text-muted d-block mt-2">
                    info@tekworthy.com
                  </span>
                </p>
              </div>
            </Col>
            <Col lg="8">
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ContactUs;
