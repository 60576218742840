import React from 'react';
// import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// const SOCIALS = [
//   { icon: 'mdi mdi-facebook', link: '#' },
//   { icon: 'mdi mdi-twitter', link: '#' },
//   { icon: 'mdi mdi-linkedin', link: '#' },
//   { icon: 'mdi mdi-google-plus', link: '#' },
//   { icon: 'mdi mdi-dribbble', link: '#' },
// ];
const Footer = () => (
  <React.Fragment>
    <div className="footer-alt">
      <Container>
        <Row>
          <Col lg="12">
            <div className="float-left pull-none">
              <p className="copy-rights text-muted mb-3 mb-sm-0">
                {`© ${new Date().getFullYear()} Tekworthy Inc.`}
              </p>
            </div>
            {/* <div className="float-right pull-none ">
              <ul className="list-inline social m-0">
                {SOCIALS.map(({ link, icon }) => (
                  <li className="list-inline-item">
                    <Link to={link} class="social-icon">
                      <i class={icon}></i>
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}
            <div className="clearfix"></div>
          </Col>
        </Row>
      </Container>
    </div>
  </React.Fragment>
);
export default Footer;
