import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import FocusDescription from './focus-description';

const DESCRIPTIONS = [
  {
    id: 1,
    icon: 'pe-7s-users',
    title: 'Strong relationships are important to us',
    description:
      'We treat your project as if it is our own, and focus on building long-term relationships with all our clients through ongoing and transparent communication.',
  },
  {
    id: 2,
    icon: 'pe-7s-like',
    title: 'We work with passion to serve our clients',
    description:
      'Our passion for our work keeps us focused on delivering your project with high-quality, and celebrating every milestone along the way.',
  },
  {
    id: 3,
    icon: 'pe-7s-light',
    title: 'We understand our clients’ priorities',
    description:
      'We have passionate developers who understand your business. You will be working directly with our developers to create your app, with no middle man.',
  },
];

const AboutUs = () => (
  <React.Fragment>
    <section className="section" id="about">
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }}>
            <div className="about-title mx-auto text-center">
              <h2 className="font-weight-light">
                A mobile and web application development company
              </h2>
              <p className="text-muted pt-4">
                We are a team of dedicated technology strategists and
                developers, eager to explore the many opportunities that exist
                in every industry to leverage mobile technology for your
                employees and your customers.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="margin-t-50">
          {DESCRIPTIONS.map((desc) => (
            <FocusDescription key={desc.id} {...desc} />
          ))}
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default AboutUs;
