import React from 'react';
import NavbarPage from '../../components/Navbar/NavbarPage';
import Section from './section';
import Clients from '../../components/Clients/clients';
import Features from '../../components/Features/features';
import Services from '../../components/Services/services';
import WebsiteDescription from '../../components/Website Description/website-description';
import AboutUs from '../../components/About Us/about-us';
import Testimonials from '../../components/Testimonials/testimonials';
import GetStarted from '../../components/Get Started/get-started';
import ContactUs from '../../components/Contact Us/contact-us';
import Footer from '../../components/Footer/footer';

const NAV_ITEMS = [
  { id: 1, idnm: 'home', navheading: 'Home' },
  { id: 2, idnm: 'features', navheading: 'Web & Mobile' },
  { id: 3, idnm: 'services', navheading: 'Services' },
  { id: 4, idnm: 'about', navheading: 'About' },
  { id: 5, idnm: 'contact', navheading: 'Contact' },
];
const TARGET_IDS = NAV_ITEMS.map((item) => item.idnm);

const Main = () => (
  <React.Fragment>
    {/* Importing Navbar */}
    <NavbarPage
      navClass="navbar-white"
      menuItems={NAV_ITEMS}
      targetIds={TARGET_IDS}
    />
    <Section />
    <Clients />
    <Features />
    <Services />
    <WebsiteDescription />
    <AboutUs />
    <Testimonials />
    <GetStarted />
    <ContactUs />
    <Footer />
  </React.Fragment>
);

export default Main;
