const config = {
  firebase: {
    apiKey: 'AIzaSyAzdsODaD5Ua2M8rnRvpSeZnYysniBFXUQ',
    authDomain: 'tekworthy-7739d.firebaseapp.com',
    databaseURL: 'https://tekworthy-7739d.firebaseio.com',
    projectId: 'tekworthy-7739d',
    storageBucket: 'tekworthy-7739d.appspot.com',
    messagingSenderId: '933845433272',
    appId: '1:933845433272:web:6f79320a388098bf276d4d',
    measurementId: 'G-1G11NQJBH1',
  },
};

export default config;
