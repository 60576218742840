import React, { useState, useRef } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { send } from '../../utils/mail';

const ContactForm = () => {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const formRef = useRef(null);

  const hideAlert = () => {
    setAlertMessage('');
    setAlert(false);
  };

  const showAlert = (message, error) => {
    setAlertMessage(message);
    setAlertType(error ? 'error' : 'success');
    setAlert(true);
  };

  const onSubmit = async (e, data) => {
    try {
      await send(data);
      if (formRef) {
        formRef.current.reset();
      }
      showAlert('Thank you. We will be in touch shortly!');
    } catch (error) {
      if (formRef) {
        formRef.current.reset();
      }
      console.log('error: ', error);
      showAlert(
        'Unfortunately an error has occurred and we cannot send your email from here. Please call us or send us an email.',
      );
    }
  };

  return (
    <div className="custom-form mt-4 pt-4">
      <Alert color={alertType} isOpen={alert} toggle={hideAlert}>
        {alertMessage}
      </Alert>
      <div id="message"></div>
      <AvForm
        name="contact-form"
        id="contact-form"
        onValidSubmit={onSubmit}
        ref={formRef}
      >
        <Row>
          <Col lg="12">
            <AvField
              type="text"
              className="form-group mt-2"
              name="name"
              id="name"
              placeholder="Your name*"
              required
              errorMessage=""
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Please enter your name',
                },
              }}
            />
          </Col>
          <Col lg="6">
            <AvField
              type="email"
              className="form-group mt-2"
              name="email"
              id="email"
              placeholder="Your email*"
              required
              errorMessage=""
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Please enter your email',
                },
              }}
            />
          </Col>
          <Col lg="6">
            <AvField
              type="text"
              className="form-group mt-2"
              name="phone"
              id="phone"
              placeholder="Your phone number"
              errorMessage=""
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <AvField
              type="textarea"
              className="form-group mt-2"
              name="comments"
              rows="4"
              id="comments"
              placeholder="Your message.."
              required
              errorMessage=""
              validate={{
                required: {
                  value: true,
                  errorMessage: 'Please enter a message',
                },
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="text-right">
            <Button className="submitBnt btn btn-custom">Send Message</Button>
            <div id="simple-msg"></div>
          </Col>
        </Row>
      </AvForm>
    </div>
  );
};

export default ContactForm;
