import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { scrollToSection } from '../../components/common/scroller';

const Section = () => {
  return (
    <React.Fragment>
      <section className="section bg-home home-half" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <Row>
            <Col lg={{ size: 8, offset: 2 }} className="text-white text-center">
              <h4 className="home-small-title">Mobile & Web Development</h4>
              <h1 className="home-title">
                We build amazing apps with and for our partners
              </h1>
              <p className="padding-t-15 home-desc mx-auto">
                For the past decade Tekworthy has partnered with startups and
                established businesses of all sizes to solve interesting
                problems using web and mobile software. We leverage open source
                and cutting edge technology to increase quality and reliability
                at reasonable cost.
              </p>
              <div
                className="btn btn-custom margin-t-30 waves-effect waves-light"
                onClick={() => scrollToSection('features')}
              >
                Learn More <i className="mdi mdi-arrow-right"></i>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
