import { analytics, db } from '../conf/firebase';
import emailjs from 'emailjs-com';

export const send = async (data) => {
  const { name, email, phone, comments } = data;
  let sendError;
  try {
    await emailjs.send(
      'worthy_mailer',
      'template_yuDymRvv',
      {
        name,
        phone,
        message_html: comments,
        reply_to: email,
      },
      'user_dWW1UpVTlE53qFutRztOM',
    );
  } catch (error) {
    console.log('failed to send email: ', error);
    sendError = error;
  }
  if (sendError) {
    data.error = JSON.stringify(sendError);
  }
  try {
    db.collection('mail').add(data);
  } catch (error) {
    console.log('error writing to the db: ', error);
  }
  try {
    analytics.logEvent('contact_made', { name, email, phone });
  } catch (error) {
    console.log('error generating analytics: ', error);
  }
  if (sendError) {
    throw sendError;
  }
};
