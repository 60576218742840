import React from 'react';
import { Col } from 'reactstrap';
import './focus-description.css';

const Focus = ({ id, title, description, icon }) => (
  <React.Fragment>
    <Col lg="4" sm="6">
      <div className="team-box text-center">
        <i className={`${icon} text-custom description-icon`}></i>
        <h4 className="team-name">{title}</h4>
        <p className="pt-2 text-muted">{description}</p>
      </div>
    </Col>
  </React.Fragment>
);

export default Focus;
