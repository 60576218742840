const easeInOutQuad = (current_time, start, change, duration) => {
  current_time /= duration / 2;
  if (current_time < 1)
    return (change / 2) * current_time * current_time + start;
  current_time--;
  return (-change / 2) * (current_time * (current_time - 2) - 1) + start;
};

const scrollTo = (start, to, duration) => {
  let change = to - start,
    currentTime = 0,
    increment = 10;

  let animateScroll = () => {
    currentTime += increment;
    let val = easeInOutQuad(currentTime, start, change, duration);
    window.scrollTo(0, val);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const scrollToSection = (sectionID, scrollDuration = 800) => {
  if (sectionID) {
    let scrollTargetPosition =
      document.getElementById(sectionID).offsetTop -
      document.querySelector("div[data-nav='list']").scrollHeight;
    scrollTo(window.pageYOffset, scrollTargetPosition, scrollDuration);
  } else {
    scrollTo(window.pageYOffset, 0, scrollDuration);
  }
};
