import React, { useState } from 'react';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from 'reactstrap';
import ScrollspyNav from '../common/scrollSpy';
import StickyHeader from 'react-sticky-header';
import logo from './logo-original.png';
import '../../../node_modules/react-sticky-header/styles.css';

const NavBar = ({ navClass, targetIds, menuItems }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <React.Fragment>
      <StickyHeader
        header={
          <Navbar
            expand="lg"
            fixed="top"
            className={`${navClass} navbar-custom sticky sticky-dark`}
          >
            <Container>
              <NavbarBrand className="logo" href="/">
                <img src={logo} alt="Tekworthy Inc." />
              </NavbarBrand>

              <NavbarToggler
                className="p-0"
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <i className="mdi mdi-menu"></i>
              </NavbarToggler>

              <Collapse id="navbarCollapse" isOpen={menuOpen} navbar>
                <ScrollspyNav
                  scrollTargetIds={targetIds}
                  activeNavClass="active"
                  scrollDuration="800"
                  headerBackground="true"
                >
                  <Nav className="navbar-nav navbar-center" id="mySidenav">
                    {menuItems.map((item) => (
                      <NavItem
                        key={item.idnm}
                        className={item.navheading === 'Home' ? 'active' : ''}
                      >
                        <NavLink href={'#' + item.idnm}>
                          {' '}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </ScrollspyNav>
              </Collapse>
            </Container>
          </Navbar>
        }
        stickyOffset={-100}
      ></StickyHeader>
    </React.Fragment>
  );
};

export default NavBar;
