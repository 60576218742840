import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './clients.css';

const CLIENTS = [
  { id: 1, img: 'assets/images/clients/xahive.png' },
  { id: 2, img: 'assets/images/clients/maviance.png' },
  { id: 3, img: 'assets/images/clients/smobilpay.png' },
  // { id: 4, img: 'assets/images/clients/yazdani.png' },
  { id: 5, img: 'assets/images/clients/infotrac.png' },
];

const Clients = () => (
  <React.Fragment>
    <section className="section-sm bg-light">
      <Container>
        <Row className="logos">
          {CLIENTS.map((client) => (
            <Col md="3" key={client.id}>
              <div className="client-images my-3 my-md-0">
                <img
                  src={client.img}
                  alt="logo-img"
                  className="mx-auto img-fluid d-block"
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default Clients;
