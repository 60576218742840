import React from 'react';
import { Col, Media } from 'reactstrap';

const ServiceBox = ({ title, description, icon }) => (
  <React.Fragment>
    <Col lg="4" className="margin-t-20">
      <div className="services-box">
        <Media>
          <i className={`${icon} text-custom`}></i>
          <Media className="ml-4" body>
            <h4>{title}</h4>
            <p className="pt-2 text-muted">{description}</p>
          </Media>
        </Media>
      </div>
    </Col>
  </React.Fragment>
);

export default ServiceBox;
