import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/performance';
import config from './config';

const app = firebase.initializeApp(config.firebase);

const db = firebase.firestore();
db.enablePersistence({ synchronizeTabs: true }).catch(function (err) {
  if (err.code === 'failed-precondition') {
    console.log('failed-precondition');
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    // ...
  } else if (err.code === 'unimplemented') {
    console.log('unimplemented');
    // The current browser does not support all of the
    // features required to enable persistence
    // ...
  }
});

const performance = firebase.performance();
const analytics = firebase.analytics();

export { app, performance, analytics, db };
export default app;
