import React from 'react';
import { Container, Row } from 'reactstrap';
import SectionTitle from '../common/section-title';
import TestimonialBox from './testimonial-box';

const TESTIMONIALS = [
  {
    id: 1,
    image: 'assets/images/testimonials/user-3.jpg',
    name: 'J. CHEAMBE',
    cmpName: 'Maviance GmbH',
    message:
      '“The team at Tekworthy has been an invaluable part of our mobile strategy, working together with us in the management, development, deployment and support of several mobile applications.”',
  },
  {
    id: 2,
    image: 'assets/images/testimonials/user-1.jpg',
    name: 'I. GROPPE',
    cmpName: 'Infotrac, Inc.',
    message:
      '“When building an app, the decisions made at the beginning in terms of technology & architecture have a huge impact on the future of the software. Tekworthy guided us through those decisions with an excellent understanding of current trends, developed a responsive & reliable backend, and brought our developers up to speed to produce an app with which we are very happy with.”',
  },
  {
    id: 3,
    image: 'assets/images/testimonials/user-2.jpg',
    name: 'D. HANKS',
    cmpName: 'Baha’i National Centre of Canada',
    message:
      '“Working with Tekworthy felt like a true collaboration. They listened to what my needs and interests were, and they also offered intelligent suggestions on how to improve it.”',
  },
  // {
  //   id: 4,
  //   image: 'assets/images/testimonials/user-1.jpg',
  //   name: 'D. MOHAJER',
  //   cmpName: 'XAHIVE Corporation',
  //   message:
  //     '“TekWorthy was able to understand and reflect back my ideas in a way that indicated that they knew how to implement what I wanted and how there might be additional opportunities for quality in my planned designs.”',
  // },
];

const Testimonials = () => (
  <React.Fragment>
    <section className="section bg-light" id="testi">
      <Container>
        <SectionTitle
          title="What they've said"
          description="We pride ourselves in our partnerships and develop longterm relationships with our clients, often working on several projects together."
        />

        <Row className="margin-t-50">
          {TESTIMONIALS.map((testimonial) => (
            <TestimonialBox key={testimonial.id} {...testimonial} />
          ))}
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default Testimonials;
