import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { scrollToSection } from '../../components/common/scroller';

const GetStarted = () => (
  <React.Fragment>
    <section className="section section-lg bg-home">
      <div className="bg-overlay"></div>
      <Container>
        <Row>
          <Col lg={{ size: 8, offset: 2 }} className="text-center">
            <h1 className="get-started-title text-white">Let's Get Started</h1>
            <div className="section-title-border margin-t-20 bg-white"></div>
            <p className="section-subtitle font-secondary text-white text-center padding-t-30">
              We look forward to hearing from you. We can help you determine
              next steps.{' '}
            </p>
            <div
              className="btn btn-bg-white waves-effect margin-t-20"
              onClick={() => scrollToSection('contact')}
            >
              Contact Us Now <i className="mdi mdi-arrow-right"></i>{' '}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default GetStarted;
