import React from 'react';
import { Col } from 'reactstrap';
import './testimonial-box.css';

const TestimonialBox = ({ image, name, message, cmpName }) => (
  <React.Fragment>
    <Col lg="4">
      <div className="testimonial-box margin-t-30">
        <div className="testimonial-decs p-4">
          <div className="testi-icon">
            <i className="mdi mdi-format-quote-open display-2"></i>
          </div>
          <i className="pe-7s-user text-custom user-icon"></i>
          <div className="p-1">
            <h5 className="text-center text-uppercase mb-3">
              {name} -{' '}
              <span className="text-muted text-capitalize">{cmpName}</span>
            </h5>
            <p className="text-muted text-center mb-0">{message}</p>
          </div>
        </div>
      </div>
    </Col>
  </React.Fragment>
);

export default TestimonialBox;
