import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { scrollToSection } from '../../components/common/scroller';

const WebsiteDescription = () => (
  <React.Fragment>
    <section className="section bg-home">
      <div className="bg-overlay"></div>
      <Container>
        <Row>
          <Col lg="12" className="text-center">
            <h2 className="text-white">Build your dream mobile app today</h2>
            <p className="padding-t-15 home-desc mx-auto">
              Getting started with your project idea is easier than you think.
              Let us know what you are thinking and we can help you bring your
              idea to life.
            </p>
            <div
              className="btn btn-bg-white margin-t-30 waves-effect waves-light"
              onClick={() => scrollToSection('contact')}
            >
              Contact Us Now
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default WebsiteDescription;
