import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { scrollToSection } from '../../components/common/scroller';

class Features extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="section" id="features">
          <Container>
            <Row className="align-items-center">
              <Col lg="6" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>Full-stack web and mobile app development</h3>
                  <p className="text-muted web-desc">
                    Our team of engineers has a wealth of experience developing
                    mobile and web applications for startups and established
                    partners in industries as diverse as health, security, food,
                    retail, finance and banking.
                  </p>
                  <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                    <li className="">
                      From the seed of your idea we craft a finished product.
                    </li>
                    <li className="">
                      We work closely with you through the design and
                      implementation process.
                    </li>
                    <li className="">
                      We listen to your feedback and provide viable solutions.
                    </li>
                  </ul>
                  <div
                    className="btn btn-custom margin-t-30 waves-effect waves-light"
                    onClick={() => scrollToSection('contact')}
                  >
                    Start Now <i className="mdi mdi-arrow-right"></i>
                  </div>
                </div>
              </Col>
              <Col lg="6" className="order-2 order-lg-1">
                <div className="features-box mt-5 mt-lg-0">
                  <h3>Leveraging the latest technologies</h3>
                  <p className="text-muted web-desc">
                    Modern tools are increasing developer effciency, which
                    translates to better quality work for you at lower cost
                    while maintaining the quality users have come to expect from
                    modern applications.
                  </p>
                  <ul className="text-muted list-unstyled margin-t-30 features-item-list">
                    <li className="">We put a lot of effort in design.</li>
                    <li className="">
                      The most important ingredient of successful website.
                    </li>
                    <li className="">Submit Your Orgnization.</li>
                  </ul>
                  <div
                    className="btn btn-custom margin-t-30 waves-effect waves-light"
                    onClick={() => scrollToSection('contact')}
                  >
                    Start Now <i className="mdi mdi-arrow-right"></i>
                  </div>
                </div>
              </Col>
              {/* <Col lg={{size: 7, order: 2, }} xs={{order : 1}}>
                                <div className="features-img mx-auto mr-lg-0">
                                    <img src="assets/images/growth-analytics.svg" alt="macbook" className="img-fluid" />
                                </div>
                            </Col> */}
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
